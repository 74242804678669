"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecurityButton = exports.SecurityHeaderText = exports.SecurityHeader = exports.SecurityIcon = exports.Divider = exports.SecurityItem = exports.SecurityItems = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var ui_components_1 = require("@imbox/ui-components");
exports.SecurityItems = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-radius: 8px;\n"], ["\n  border: 1px solid ", ";\n  border-radius: 8px;\n"])), function (props) { return props.theme.color.neutral2; });
exports.SecurityItem = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 24px 16px;\n  gap: 24px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 24px 16px;\n  gap: 24px;\n"])));
exports.Divider = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 1px;\n  background: ", ";\n"], ["\n  height: 1px;\n  background: ", ";\n"])), function (props) { return props.theme.color.neutral2; });
exports.SecurityIcon = (0, styled_components_1.default)(ui_components_1.Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 29px;\n  width: 20px;\n  align-self: start;\n"], ["\n  font-size: 29px;\n  width: 20px;\n  align-self: start;\n"])));
exports.SecurityHeader = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 6px;\n"], ["\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 6px;\n"])));
exports.SecurityHeaderText = (0, styled_components_1.default)(ui_components_1.Typography)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-right: 8px;\n"], ["\n  margin-right: 8px;\n"])));
exports.SecurityButton = (0, styled_components_1.default)(ui_components_1.Button)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 16px;\n\n  ", "\n"], ["\n  margin-top: 16px;\n\n  ", "\n"])), function (props) {
    return props.type === 'Secondary' && (0, styled_components_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      div {\n        color: #66656a;\n      }\n    "], ["\n      div {\n        color: #66656a;\n      }\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
