"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultTheme = void 0;
var ui_components_1 = require("@imbox/ui-components");
exports.defaultTheme = __assign(__assign({}, ui_components_1.uiComponentsTheme), { colors: {
        conversationColors: {
            '1': { primary: '#aee394', secondary: '#59b02e' },
            '2': { primary: '#92e3cf', secondary: '#2daf8f' },
            '3': { primary: '#e194b5', secondary: '#ac3065' },
            '4': { primary: '#e194cc', secondary: '#ac308a' },
            '5': { primary: '#e194e2', secondary: '#ac2fae' },
            '6': { primary: '#be94e3', secondary: '#732eb0' },
            '7': { primary: '#92b4e3', secondary: '#2d64af' },
            '8': { primary: '#92cfe3', secondary: '#2d8faf' },
            '9': { primary: '#92e2e3', secondary: '#2dadaf' },
            '10': { primary: '#e1ce94', secondary: '#ac8d30' },
        },
        status: {
            online: {
                normal: '#1dce73',
                dark: '#19bf69',
            },
            away: '#f79232',
            menuAway: '#d63535',
            offline: '#8e8e8e',
        },
    }, phoneAnimation: {
        animationTiming: 'ease-in-out',
        animationDurationMs: 250,
        animationDurationMsFast: 150,
        medium: 'ease-in-out 250ms',
        fast: 'ease-in-out 150ms',
    }, color: {
        primary: '#634AAF',
        primaryLight1: '#F0EDF8',
        primaryLight2: '#E0DBEF',
        primaryLight3: '#C1B7DF',
        primaryLight4: '#A192CF',
        primaryDark1: '#433277',
        primaryDark2: '#2E2251',
        accent: '#2BBF98',
        accentLight1: '#EAF9F5',
        accentLight2: '#D5F2EA',
        accentLight3: '#AAE5D6',
        accentLight4: '#80D9C1',
        accentDark1: '#145846',
        accentDark2: '#0E3C30',
        white: '#FFFFFF',
        neutral1: '#F5F4F6',
        neutral2: '#EBE9ED',
        neutral3: '#D6D4DC',
        neutral4: '#99979E',
        neutral5: '#66656A',
        neutral6: '#141415',
        info: '#2A77AA',
        infoLight: '#E5EFF5',
        infoDark: '#0F2B3D',
        success: '#15C26B',
        successLight: '#E3F8ED',
        successDark: '#084627',
        warning: '#FFAE0C',
        warningLight: '#FFF5E2',
        warningDark: '#5C3F04',
        danger: '#DD3131',
        dangerLight: '#FBE6E6',
        dangerDark: '#501212',
    } });
